// Define screen size variables
$small-screen: 480px;
$medium-screen: 768px;
$large-screen: 1024px;
$extra-large-screen: 1440px;

$font-sm: 12px;
$font-md: 24px;
$font-lg: 36px;
$font-xl: 48px;
$font-xxl: 60px;

$theme-blue: #0457ac;
$theme-green: #cee020;
$theme-white: #ffffff;
$theme-error: #dd471d;

$step-size-sm: 62px;
$step-size-lg: 80px;

// Styles for small screens (less than 480px)
@media (max-width: $medium-screen) {
  // Your styles for small screens here
  .mobile-hide {
    display: none;
  }
}

// Styles for medium screens (between 480px and 768px)
@media (min-width: $small-screen) and (max-width: $medium-screen) {
  // Your styles for medium screens here
}

// Styles for large screens (greater than 768px)
@media (min-width: $medium-screen) and (max-width: $large-screen) {
  // Your styles for large screens here
}

// Styles for extra-large screens (greater than 1024px)
@media (min-width: $large-screen) {
  // Your styles for extra-large screens here
}

// Default styles (no media query)
// These styles will apply to all screen sizes
// You can also override specific properties inside media queries
.mainContainer {
  font-size: $font-sm;    
  position: relative;
  font-family: Inter;
//   min-height: 53vh;
  padding-bottom: 1em;
  background-color: $theme-blue;
  background-image: url('../images/Layerright.png'), url('../images/Layerleft.png');
  background-repeat: no-repeat;
  background-position: right -180px top 100px, left -50px bottom 0px;
  background-size: 300px 300px, 190px 160px;

  .overview {
    padding: 1em;
    color: $theme-white;
    .overview-logo-wrapper {
      width: 75px;
      height: 75px;
      margin: 0 auto;
      background-color: $theme-white;
      border-radius: 50%;
      margin-top: 20px;
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      .overviewlogo {
        width: 85%;
      }
    }
    .overviewtxt {
      text-align: center;
      margin-top: 1em;
      margin-bottom: 2em;
    }
  }
  // Your default styles here

  @media (min-width: $small-screen) {    
      padding-right: 10%;
      padding-left: 10%;
    //   background-position: right  calc(-10% - 100px) top 100px, left  10% top 200px; // calc of different measurement can't be used in scss

  }
  @media (min-width: $medium-screen) {
    // Override properties for large screens
    // background-position: right max(-170px, calc(-450px + 25%))   top 20px;
    background-position: right -100px top 20px, left  10% top -35px;
    background-size: 300px 300px, 475px 390px;

    .overview {
    //   margin: 0 50% $step-size-lg/2 5%;
      margin: 0 50% $step-size-lg/2 0%;
      .overview-logo-wrapper{
        width: 100px;
        height: 100px;
        margin: 40px auto 0px;

      }
      .overviewtxt {
        margin-top: 2em;
        line-height: 1rem;
        margin-bottom: 0;
      }
    }
  }
  @media (min-width: $extra-large-screen) {
    // Override properties for large screens
   min-height: 380px;
   .overview {
    .overview-logo-wrapper{
      width: 125px;
      height: 125px;
      margin: 40px auto 0px;

    }
  }
  }
}

.formContainer {
  font-size: 1rem;
  background-color: $theme-green;
  font-family: Inter;
  padding: 20px 15px;
  margin: 1em;
  margin-bottom: 50px;
  width: calc(100% - 2em);
  border-radius: 16px;
  box-shadow: 0px 4px 39px 0px rgba(0, 0, 0, 0.25);

  h2 {
    color: #0457ac;
    text-align: center;
    font-size: $font-lg;
    font-family: Inter;
    font-weight: 600;
    line-height: 137.523%;
    margin-top: 0px;
    margin-bottom: 0.5rem;
  }
  h3 {
    color: #0457ac;
    text-align: center;
    font-size: $font-md;
    font-weight: 300;
    margin: 1rem 0 2rem;
  }
  form {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    input {
      width: 100%;
      margin-bottom: 1em;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    input[type="submit"] {
      background-color: $theme-blue;
      color: $theme-white;
      margin: 20px 20px 0;
      width: calc(100% - 40px);
      font-size: 20px;
      font-weight: 700;
    }
    .error-message{
      text-align: center;
      color: $theme-error;
      font-size: $font-sm;
    }
    .resend-otp-container{
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      height: 2em;

      .resend-otp{
        text-decoration: underline;
        cursor: pointer;
      }
      .disabled{
        cursor: not-allowed;
      }
      .timer{
        font-size: .7em;   
      }
      .circle{
        height: 2em;
        width: 2em;
        border: 1px solid $theme-blue;
        border-radius: 50%;
      }
      .center-content{
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media (min-width: $medium-screen) {
    // Override properties for large screens
    position: absolute;
    min-width: 300px;
    width: 30%;
    top: 40px;
    left: 75%;
    transform: translateX(-50%);
    max-width: 560px;
    padding: 2em;
    h2 {
      font-size: $font-lg;
    }
    h3 {
      font-size: $font-md;
    }
    // @media (min-height: 600px) {
    //   top: 20%;
    // }
  }
  @media (min-width: $extra-large-screen) {
    min-width: 500px;
    h2 {
      font-size: $font-xl;
    }
    // h3 {
    //   font-size: $font-lg;
    // }
    form {
      input[type="submit"] {
        font-size: $font-md;
      }
    }
  }
}

@media (min-width: $medium-screen){
  .steps-footer-wrapper{
    background-image: url('../images/Vectorbottom.png');
    background-repeat: no-repeat;
    background-position: left 50px top 100px;
    background-size: 100px 100px;
  }
}

.stepsContainer {
  position: relative;
  font-family: Inter;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  top: -$step-size-sm/2;

  .step-wrapper {
    // position: absolute;
    top: -$step-size-sm/2;
    width: 80px;
    text-align: center;
  }
  .step {
    height: $step-size-sm;
    width: $step-size-sm;
    margin: 0 auto;
    padding: 10px;
    border-radius: 50%;
    background: #ffffff;
    border: 6px solid $theme-blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .step-1 {
    left: 0%;
  }
  .step-2 {
    left: calc(50% - #{$step-size-sm/2});
  }
  .step-3 {
    right: 0%;
  }
  .step-description {
    color: #0457ac;
    margin: 20px -3px;
    font-family: "Inter";
    font-size: 14px;
  }
  @media (min-width: $medium-screen) {
    margin: 0 50% 0px 10%;
    width: 40%;
  }
  @media (min-width: $extra-large-screen) {
    top: -$step-size-lg/2;
    .step {
      height: $step-size-lg;
      width: $step-size-lg;
      padding: 15px;
    }
  }
}



footer{
    color: #504646;
    text-align: center;
    font-size: 20px;
    font-family: Inter;
    font-weight: 600;
    line-height: 129.523%;
    
    @media (min-width: $medium-screen) {
        // padding: 130px  15% 50px;
        padding: 1px 15% 50px;
    }

    @media (min-width: $extra-large-screen) {
        padding: 0px  15% 50px;
      }
}