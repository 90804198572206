$medium-screen: 768px;
$theme-blue: #0457ac;
$them-sidenav-bg: #2A4F82;
$theme-white: #FFF;
$header-height: 70px;




.full-screen-pop-over {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
}

header {
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height;
  padding: 0 5px;
  background-color: $theme-blue;
  top: 0px;
  z-index: 100;
}

.side-nav-contianer {
  width: 70%;
  height: 100%;
  min-width: 200px;
  max-width: 300px;
  background-color: $them-sidenav-bg;
  position: fixed;
  top: 0;
  right: -70%;
  z-index: 200;
  transition: right 0.3s ease-in-out;

  &.open {
    right: 0;
  }

  /* CSS code for slide-in animation */
  @keyframes slideIn {
    from {
      right: -250px;
    }

    to {
      right: 0;
    }
  }

  /* CSS code for slide-out animation */
  @keyframes slideOut {
    from {
      right: 0;
    }

    to {
      right: -250px;
    }
  }

  .menu {
    position: fixed;
    top: 27px;
    right: 5px;
  }

  .close-sidenav {
    display: flex;
    justify-content: end;
    margin: 25px 20px;

  }

  .nav-list {
    margin-top: $header-height;

    .btn {
      width: 100%;
      border-bottom: 1px solid $theme-white;
      border-radius: 0px;
      background-color: $them-sidenav-bg;
      padding: 16px;
    }
  }

  .logout-wrapper {
    padding: 10px 25px;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      background-color: $them-sidenav-bg;
      border: 1px solid $theme-white;
      border-radius: 9px;
      height: 45px;
      width: 100%;
      min-width: 200px;
      max-width: 300px;
      align-items: center;
      display: flex;
      justify-content: center;
      color: $theme-white;

      img {
        margin-left: 15px;
      }
    }
  }

}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  @media (min-width: $medium-screen) {
    display: none;
  }
}

.logo-img {
  margin-left: 20px;
}



#menu-list {
  @media (max-width: $medium-screen) {
    display: none;
  }
}

.btn {
  background-color: $theme-blue;
  text-decoration: none;
}

a,
.btn.logout {
  color: #ffffff;
  text-decoration: none;
  font-family: "Inter";
}

.btncont a {
  background-color: #ffffff;
  color: #0054aa;
  border-style: none;
  background: transparent;
  font-weight: bold;
}

.btncont {
  border-radius: 9px;
  border: none;
  width: 159px;
  padding: 13px;
  margin-right: 34px;
}

body {
  margin: 0;
  padding: 0;
}

.button-img {
  display: none;
}

.container {
  display: flex;
  flex-direction: column;
}

.our-works-container {
  padding: 60px 20vw 0 20vw;

}

.our-works {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
}

h1.our-works {
  color: black;
  font-family: "Inter";
  font-size: 32px;
  font-weight: bold;
}

h3.our-works {
  color: #6D6D6D;
  font-size: 16px;
  font-family: "Inter";
  font-weight: normal;
}

.product-container {
  padding-top: 50px;
  background-image: url('../images/OUR WORKS.png');
  background-repeat: no-repeat;
  background-position: 0 20px;
  // background-size: contain;
  background-size: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 100px;
}

.product-img {
  height: 311px;
  // width: 320px;
  background-repeat: no-repeat;
  background-size: 100%;
  transition: transform 250ms;
  transform: translateY(-10px);
}

.product-card {
  // height: 438px;
  height: 457px;
  width: 420px;
  background: #ffffff;
  box-shadow: 0px -6px 14px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  margin: 40px;
  overflow: hidden;
  position: relative;
}

.product-info {
  padding: 20px;
  position: absolute;
  background: white;
  transition: top 250ms;
  top: 280px;
  min-width: 20rem;
  width: 100%;

  h2,
  h5 {
    margin: 10px auto;
    font-family: "Inter";
  }

  h5 {
    line-height: 1.2em;
    color: #696969;
    margin-bottom: 0.8em;
    font-weight: normal;
    min-height: 4.8em;
    font-size: .8rem;
  }

  .view-filter-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    button.view-filter {
      height: 50px;
      width: 100%;
      background: #7400B8;
      border-radius: 19px;
      color: white;
      border: none;
      font-weight: 600;
      cursor: pointer;
    }

    button.unique-codes {
      height: 50px;
      width: 100%;
      color: #7400B8;
      margin-left: 10px;
      border-radius: 19px;
      border-color: #9747FF;
      background: white;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1px 10px;
      font-weight: 600;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
      }

      // border:none;
    }
  }
}

.unique-code-contianer {
  position: fixed;
  display: none;
  top: 70px;
  overflow-y: scroll;
  height: calc(80% - 90px);
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: center;
  background: rgba(0, 0, 0, 0.65);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  border-radius: 0 0 26px 26px;
  padding: 30px;
  transition: all 0s;

  &.show {
    transform: translateY(0);
    transition-duration: .5s;
  }

  &.hide {
    transform: translateY(-100vh);
    transition-duration: .5s;
  }

  .unique-code-item {
    color: #FFFFFF;
    flex-basis: 20%;
    padding: 10px;
    margin: 20px 10px;
    border: 1px solid gray;
    border-radius: 20px;
    display: flex;
    justify-content: space-around;
    min-width: 150px;
    font-weight: 600;

    img {
      height: 15px;
      width: 15px;
    }
  }

  @media (max-width: $medium-screen) {
    top: 0px;
    height: calc(80% - 20px);
  }

  .blink {
    animation: blink-animation .6s cubic-bezier(0, 0.32, 1, 0.61);
  }

  @keyframes blink-animation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.2;
    }

    100% {
      opacity: 1;
    }
  }

  // @keyframes slideIn {
  //   from {
  //     top: -100%; /* Start position above the viewport */
  //   }
  //   to {
  //     top: 90px; /* Final position centered vertically */

  //   }
  // }
  // @media (max-width: $medium-screen) {
  //   @keyframes slideIn {
  //     from {
  //       top: -100%; /* Start position above the viewport */
  //     }
  //     to {
  //       top: 30px; /* Final position centered vertically */

  //     }
  //   }
  // }
}



.slide-out {
  animation: slideOut 0.5s forwards;
}

@keyframes slideOut {
  from {
    top: 90px;
  }

  to {
    top: -100%;
  }
}



.unique-code-contianer-close {
  position: fixed;
  bottom: calc(22vh);
  left: 50%;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  transform: translate(-50%, 50%);
  background: rgb(170, 168, 168);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    height: 22px;
    width: 22px;
  }

  @media (min-width: $medium-screen) {
    width: 70px;
    height: 70px;

    img {
      height: 30px;
      width: 30px;
    }
  }
}

.product-card:hover .product-img {
  transform: translateY(0px);
}

.product-card:hover .product-info {
  transition: top 250ms;
  top: 180px
}

.tag-container {
  display: flex;
  font-size: 12px;

  .tag {
    background: rgba(255, 169, 67, 0.68);
    border: 1px solid #2670ff;
    border-radius: 24px;
    margin: 5px 10px 5px 0px;
    padding: 5px;
    font-family: "Inter";
  }

  & :first-child {
    background: rgba(122, 119, 248, 0.4);
  }

  & :nth-child(3) {
    background: rgba(122, 119, 248, 0.4);
  }
}


.filter-buttons {
  position: fixed;
  bottom: 30px;
  background: rgba(0, 0, 0, 0.54);
  backdrop-filter: blur(14px);
  border-radius: 15px;
  width: 50%;
  // min-width: 340px;
  height: 60px;
  padding: 7px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 100;

  button {
    // font-size: 20px;
    font-size: 16px;
    border-radius: 8px;
    height: 100%;
    flex: 1;
    margin-left: 4px;
    cursor: pointer;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;

    .button-icon {
      margin-right: 10px;
      height: 20px;
      width: 20px;
    }

    span {}

  }

}

.filter-container {
  position: fixed;
  bottom: 94px;
  // height: calc(90vh - 140px);
  height: calc(78vh - 140px);
  min-width: 340px;
  width: 50%;
  left: 50%;
  transform: translateX(-50%);

  background: rgba(0, 0, 0, 0.65);
  backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 26px 26px 0px 0px;
  padding: 30px;
  font-family: 'Inter';
  z-index: 50;

  .filter-wrapper {
    display: flex;
    flex-flow: row wrap;
    // justify-content: space-around;
    align-items: center;

    .filter-item {
      color: #FFFFFF;
      // flex-basis: 50%;
      flex-basis: 25%;
      padding: 10px;
      cursor: pointer;
      transition: color 0.3s ease;
    }

    .filter-item:hover {
      color: yellow;
    }
  }

  @media (max-width: $medium-screen) {
    width: 90%;

    .filter-wrapper {
      .filter-item {
        flex-basis: 50%;
      }
    }
  }
}

.filter-left {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
  color: white;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;

  img {
    margin-left: 5px;
  }
}

.filter-buttons.visible {
  display: flex;
}

.filter-buttons.hidden {
  // display: none;
  visibility: hidden;
}




.applied-filter-container {
  position: sticky;
  z-index: 50;
  top: $header-height;
  width: 100%;
  background: white;
  overflow-x: scroll;
  white-space: nowrap;
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
  -ms-overflow-style: none;
  padding: 2px;
  background: #FFF;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);

  .applied-filter-item {
    display: inline-block;
    border-radius: 75px;
    height: 43px;
    min-width: 90px;
    padding: 0 18px;
    background: #7400B8;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin-right: 12px;

    .flex-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      .filter-text {
        color: #FFF;
        font-size: 12px;
        font-family: Inter;
        font-weight: 700;
        margin-right: 10px;
      }

      img {
        cursor: pointer;
      }
    }
  }

  @media (max-width: $medium-screen) {
    top: 0px;
  }
}

.applied-filter-container::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari, and Opera */
}

@media (max-width: $medium-screen) {
  header {
    position: static;
  }

  .logout {
    display: block;
  }

  .product-container {
    background-image: none;
  }

  .our-works-container {
    background-image: url('../images/OUR WORKS1.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 38px;
    padding: 35px 40px 0 40px;
  }


  .product-card {
    margin: 20px;
    // height: 540px;
    height: 600px;
  }

  .product-card .product-img {
    transform: translateY(0px);
  }

  .product-card:hover .product-img {
    transform: translateY(0px);
  }

  .product-card:hover .product-info {
    transition: none;
    top: 280px
  }

  .filter-left {
    margin: 0 10px;

    span {
      display: none;
    }
  }

  .filter-buttons {
    width: 90%;
  }

}

@media (min-width: 1400px) {
  .product-card {
    margin: 40px;
    height: 582px;
    width: 417px;

    .product-img {
      width: 417px;
      height: 405px;
    }

    .product-info {
      top: 405px;
    }
    .product-card:hover .product-info {
      transition: top 250ms;
      top: 100px !important;
    }
  }

  .product-card:hover .product-info {
    top: 315px;
    // top: 280px;
  }

  .filter-left {
    margin: 0 60px;
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
  }

  .filter-buttons button .button-icon {
    margin-right: 25px;
  }
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  position: relative;
    
  input {
    padding: 10px;
    font-size: 15px;
    // width: 300px;
    width: 100%;
    margin-right: 10px;
    border: 1px solid #CCC;
  border-radius: 8px;
  padding-left: 34px;
  color: rgba($color: #000, $alpha: .7);


    &:focus{
      outline: none;
    }
  }

  .search-button {
    padding: 10px 20px;
    background-color: #0457ac;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }
}
.search-icon{
  position: absolute;
  left: 10px;
  width: 18px;
  height: 18px;
  background-image: url('../../images/icon.svg'); /* Add your icon path */
  background-size: contain;
  background-repeat: no-repeat
}

@media (max-width: $medium-screen) {
  .search-bar {

    flex-direction: column;
    align-items: stretch;

    input {
      width: 100%;
      margin-right: 0;
      // margin-bottom: 10px;
    }

    .search-button {
      width: fit-content;
    }
  }
}